<template>
  <div class="NamesView">
    <div
      class="header"
      :style="style">
      <div />
      <div class="navIndication">
        {{ cardIndex + 1 }}/{{ nameIds.length }}
      </div>
      <div
        class="exitButton --global-clickable"
        @click="back()">
        exit
      </div>
    </div>
    <div class="body">
      <div class="spacer-1" />
      <Carousel
        :items="carouselItems"
        :index="cardIndex"
        :transition-duration="0.5">
        <template
          slot="item"
          slot-scope="props">
          <NameWithCodesCard v-bind="props" />
        </template>
      </Carousel>

      <!-- <div class="cardContainer">
        <transition :name="transitionName">
          <NameWithCodesCard :name-id="currentNameId" :key="cardIndex"/>
        </transition>
      </div> -->
      <div class="spacer" />
      <div class="footer">
        <NavigationWithArrows
          class="navigation"
          :length="nameIds.length"
          :index="cardIndex"
          auto-disable="both"
          @click-prev="prev()"
          @click-next="next()" />
        <div
          class="startButton --global-clickable"
          :class="{ disabled: cardIndex < nameIds.length - 1 }"
          :style="{ backgroundColor: theme.primaryColor }"
          @click="start()">
          Play
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gameHelper from '@/modules/games-shared/game-helper';
import Carousel from '@/modules/games-shared/components/Carousel';
import NavigationWithArrows from '@/modules/games-shared/components/NavigationWithArrows';

import NameWithCodesCard from '../components/NameWithCodesCard';

export default {
    components: { NavigationWithArrows, Carousel, NameWithCodesCard },
    inject: ['module', 'theme'],
    data() {
        return {
            // tabIndex: 0,

            // cardIndex: 0,
            direction: 1
        };
    },
    computed: {
        style() {
            return {
                backgroundImage: this.theme.coverImage ? `url(${this.theme.coverImage})` : null
            };
        },
        transitionName() {
            return this.direction > 0 ? 'out-left' : 'out-right';
        },
        categoryId() {
            return this.$route.params.categoryId;
        },
        category() {
            return this.module.state.categories[this.categoryId];
        },
        nameIds() {
            return this.module.state.session.nameIds;
        },
        currentNameId() {
            return this.nameIds[this.cardIndex];
        },
        cardIndex() {
            return parseInt(this.$route.params.index);
        },
        carouselItems() {
            return this.nameIds.map(nameId => {
                return {
                    nameId
                };
            });
        }
    },
    methods: {
        back() {
            this.$router.push({ name: 'NameSystemSessionView', params: { categoryId: this.categoryId } });
        },
        prev() {
            this.direction = -1;
            this.$router.replace({
                name: 'NameSystemNamesView',
                params: { categoryId: this.categoryId, index: this.cardIndex - 1 }
            });
        },
        next() {
            this.direction = 1;
            this.$router.replace({
                name: 'NameSystemNamesView',
                params: { categoryId: this.categoryId, index: this.cardIndex + 1 }
            });
        },
        async start() {
            gameHelper.createPracticeSession({
                gameId: 'faces',
                exitUrl: this.$router.resolve({
                    name: 'NameSystemCategoryView',
                    params: { categoryId: this.categoryId }
                }).href,
                sessionOptions: {
                    numberOfItems: this.module.state.session.nameIds.length,
                    includeName: true,
                    items: this.module.state.session.persons.map(item => {
                        return Object.assign(item, { country: 'Western' });
                    }),
                    itemMemorizeTimeLimit: 0,
                    itemQuizTimeLimit: 0
                },
                navigate: true
            });
        }
    }
};
</script>

<style scoped lang="scss">
.NamesView {
    display: flex;
    flex-direction: column;

    &.showingNameDetail {
        & > * {
            // opacity: 0.1;
            visibility: hidden;
        }
        .header {
            visibility: visible;
            opacity: 0.1;
        }
    }
}
.spacer {
    flex: 1;
}
.header {
    height: $names-headerHeight;

    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;

    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 0em 0; //2em;
    background-color: rgba(black, 0.2);
    color: rgba(white, 0.7);
    flex-shrink: 0;
}
.exitButton {
    align-self: stretch;
    display: flex;
    align-items: center;
    padding-left: 1em;
    padding-right: 2em;
}
.navIndication {
    position: absolute;
    top: 0;
    left: 5em;
    right: 5em;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title {
    color: rgba(white, 0.7);
}
.top {
    align-self: stretch;
    display: flex;
    align-items: center;
    padding: 1em;
}
.spacer-1 {
    flex: 1;
    height: 6em;
}
.topEdge {
    width: 1em;
}
.backIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
}
.icon {
    $size: 8em;
    width: $size;
    height: $size;
    background-color: white;
    border-radius: 50%;
}
.titlePrefix {
    font-size: 140%;
    flex: 1;
    text-align: center;
}
.body {
    // padding: 2em;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.footer {
    padding-bottom: 2em;
    position: relative;
}

.startButton {
    $size: 4em;
    width: $size;
    height: $size;
    color: white;
    border-radius: 4em;
    // padding: 1em 2em;
    // text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 1em;
    right: 2em;

    transition: opacity 0.5s;

    &.disabled {
        opacity: 0;
        pointer-events: none;
    }
}

.cardContainer {
    position: relative;
    // width: 20em;
    flex: 1;
    align-self: stretch;
    // border: 1px solid red;
    // height: 20em;
    display: flex;
    justify-content: center;
    // align-items: center;
    //
    // margin-top: 5em;
}
.Carousel {
    // position: relative;
    // width: 20em;
    // flex: 1;
    align-self: stretch;
    // margin-left: -2em;
    // margin-right: -2em;

    // border: 1px solid red;
    height: 28em;
    // display: flex;
    // justify-content: center;
}

.NameWithCodesCard {
    min-height: 20em;
    width: 100%;

    $transitionDuration: 0.5s;
    position: absolute;
    // top: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;

    &.out-left-enter-active,
    &.out-left-leave-active,
    &.out-right-enter-active,
    &.out-right-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
    }

    &.out-left-enter {
        // opacity: 0;
        // transform: scale(0.2);
        transform: translate3d(100%, 0, 0) scale(0.5);
    }
    &.out-right-enter {
        // opacity: 0;
        // transform: scale(0.2);
        transform: translate3d(-100%, 0, 0) scale(0.5);
    }

    &.out-left-leave-to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
    &.out-right-leave-to {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
    }
}
</style>
